import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { api, api_delete, api_update } from '../api.service';
import { BillsTable } from '../bills';
import Confirm from '../components/confirm';
import { InvoicesTable } from '../invoices';
import {
  CurrencyField,
  DateField,
  FileField,
  SelectField,
  TextAreaField,
  TextField,
} from './fields/';
import FormWrapper from './form-wrapper';

export default function ProjectForm(props) {
  const params = useParams();
  const navigate = useNavigate();
  let id = params.id ?? props.id;
  let [searchParams, setSearchParams] = useSearchParams();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [bills, setBills] = useState([]);
  const [userData, setUserData] = useState();

  const [initialised, setInitialised] = useState(false);
  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/app/bill`).then((x) => {
      setBills(x.filter((x) => x.project == id));
    });
    api(`${process.env.REACT_APP_API_URL}/app/invoice`).then((x) => {
      setInvoices(x.filter((x) => x.project == id));
    });
  }, [id]);

  function validation(values) {
    if (validCompletionDate(values.startDate, values.completedDate) === false) {
      return false;
    }

    return true;
  }

  function validCompletionDate(start, completed) {
    if (start && completed) {
      return completed.valueOf() > start.valueOf();
    }
    return true;
  }

  function deleteProject() {
    api_delete(`${process.env.REACT_APP_API_URL}/app/project/${id}`).then((x) =>
      navigate(-1),
    );
  }

  useEffect(() => {
    if (window.lendica && userData && !initialised) {
      setInitialised(true);
      const credentials = {
        partner_name: process.env.REACT_APP_LENDICA_PARTNER_NAME,
        partner_company_uuid: userData.companyData.id,
        company_name: userData.companyData.companyName,
      };
      window.lendica.init(credentials).then(() => {
        window.lendica.ibranch.render();
      });
    }
  }, [window.lendica, userData]);

  const openFundNow = (id, total) => {
    window.lendica.ibranch.openFundNow(id, total);
  };
  const openPayLater = (id, total) => {
    window.lendica.ibranch.openPayLater(id, total);
  };

  async function handleFormSubmit(values) {
    if (values?.document) {
      await api_update(
        `${process.env.REACT_APP_API_URL}/document/${values?.document}`,
        { source: 'project', sourceId: values._id },
      );
    }
    if (searchParams.get('rd')) {
      navigate(searchParams.get('rd'));
    } else {
      navigate(-1);
    }
  }

  return (
    <div className="sm:px-4">
      <Confirm
        open={confirmDelete}
        confirm={() => deleteProject()}
        cancel={() => setConfirmDelete(false)}
      ></Confirm>
      <FormWrapper
        url="app/project"
        id={id}
        {...props}
        callback={async (values) => handleFormSubmit(values)}
        validate={validation}
      >
        {(values, setValues) => (
          <>
            <div className="text-3xl font-bold tracking-tighter text-gray-800">
              Project Details
            </div>

            <div className="grid grid-cols-1 gap-x-4 sm:grid-cols-2 gap-y-6">
              <TextField
                label="Name"
                value={values.name}
                onChange={(val) => setValues({ ...values, name: val })}
                required
              />
              <TextField
                label="Location"
                value={values.location}
                onChange={(val) => setValues({ ...values, location: val })}
              />
              <FileField
                label="Contract"
                value={values.document}
                onChange={(val) => setValues({ ...values, document: val })}
                source="project"
                required
              />
              <TextAreaField
                label="Description"
                value={values.description}
                onChange={(val) => setValues({ ...values, description: val })}
                inputClass={'h-12 mt-1'}
              />
            </div>

            <div className="grid grid-cols-1 gap-6 sm:grid-cols-3 my-6">
              <TextField
                label="Project Owner"
                value={values.projectOwner}
                onChange={(val) => setValues({ ...values, projectOwner: val })}
                required
              />
              <TextField
                label="Main Contact"
                value={values.mainContact}
                onChange={(val) => setValues({ ...values, mainContact: val })}
                required
              />
              <TextField
                label="Main Contact Email"
                value={values.mainContactEmail}
                onChange={(val) =>
                  setValues({ ...values, mainContactEmail: val })
                }
                required
              />
              <DateField
                label="Project Start Date"
                value={values.startDate}
                onChange={(val) => setValues({ ...values, startDate: val })}
              />

              <DateField
                label="Target Completion Date"
                value={values.completedDate}
                onChange={(val) => setValues({ ...values, completedDate: val })}
                isValid={validCompletionDate(
                  values.startDate,
                  values.completedDate,
                )}
                invalidText="Must be after Start Date"
              />
              <SelectField
                options={[
                  { text: 'In Review', value: 'In Review' },
                  { text: 'Active', value: 'Active' },
                  { text: 'Complete', value: 'Complete' },
                ]}
                label="Project Status"
                value={values.status}
                onChange={(val) => setValues({ ...values, status: val })}
              />
              <CurrencyField
                label="Project Contract Value"
                value={values.totalPrice}
                onChange={(val) => setValues({ ...values, totalPrice: val })}
                min={0}
              />
              <CurrencyField
                label="Deposit Paid"
                value={values.deposit}
                onChange={(val) => setValues({ ...values, deposit: val })}
                min={0}
              />
              <CurrencyField
                label="Total Estimated Costs"
                value={values.totalEstimatedCosts}
                onChange={(val) =>
                  setValues({ ...values, totalEstimatedCosts: val })
                }
                min={0}
              />
            </div>

            {id !== 'new' && (
              <div className="relative">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setConfirmDelete(true);
                  }}
                  className="absolute top-0 left-0 inline-flex items-center justify-center px-4 py-2 mt-5 text-sm font-medium text-white border border-transparent rounded shadow-sm cursor-pointer hover-lift bg-rose-500 hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2"
                >
                  Delete
                </button>
              </div>
            )}
          </>
        )}
      </FormWrapper>

      <div className="mb-5 text-2xl font-medium text-gray-800">Bills</div>
      <BillsTable openBill={openPayLater} data={bills} />

      <div className="my-5 text-2xl font-medium text-gray-800">Invoices</div>
      <InvoicesTable open={openFundNow} data={invoices} />
    </div>
  );
}
