import { Fragment, useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useRole } from './hooks/useRole';
import { MANAGER_ROLE_NAME } from './utils';
import logo from './logo.png';
import logoWhite from './logo-white.png';
import { api } from './api.service';
import Loading from './components/loading';
import smallLogo from './smallLogo.png';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import useDarkMode from './hooks/useDarkMode';

import {
  ArrowDownIcon,
  ArrowLeftOnRectangleIcon,
  Cog6ToothIcon,
  CurrencyDollarIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';

import { IconBuildingStore, IconClipboardData, IconLayoutDashboard, IconLayoutSidebar, IconLogout2, IconMailDollar, IconMoneybag, IconMoonStars, IconReceiptDollar, IconSettings, IconUserDollar } from '@tabler/icons-react';

const BOTTOM_NAVIGATION = [
  { name: 'Settings', href: '/settings', icon: IconSettings },
  { name: 'Toggle Dark Mode', href: '#', icon: IconMoonStars },
  { name: 'Logout', href: '/logout', icon: IconLogout2 },
];

const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

export default function Layout() {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expandedItems, setExpandedItems] = useState([]);
  const buildrfiFinancingEnabled = useFeatureFlagEnabled('buildrfi_financing');
  const { darkMode, setDarkMode } = useDarkMode();

  const TOP_NAVIGATION = [
    { name: 'Dashboard', href: '/', icon: IconLayoutDashboard },
    { name: 'Projects', href: '/projects', icon: IconClipboardData },
    {
      name: 'Bills',
      href: '/bills',
      icon: IconReceiptDollar,
      subItems: [{ name: 'Vendors', href: '/vendors', icon: IconBuildingStore }],
    },
    {
      name: 'Invoices',
      href: '/invoices',
      icon: IconMailDollar,
      subItems: [{ name: 'Customers', href: '/customers', icon: IconUserDollar }],
    },
    { name: 'Team', href: '/user', icon: UserGroupIcon },
  ];

  if (buildrfiFinancingEnabled) {
    TOP_NAVIGATION.push({ name: 'Financing', href: '/application', icon: IconMoneybag });
  }

  const { pathname } = useLocation();
  const { isAllowed: isManager } = useRole(MANAGER_ROLE_NAME);

  useEffect(() => {
    const checkEndingStatus = () => {
      const status = user.stripeSubscriptionStatus;
      if (status?.startsWith('Ending')) {
        const dateString = status.replace('Ending on ', '');
        const endDate = new Date(dateString);
        const currentDate = new Date();

        if (endDate < currentDate) {
          window.location.assign('/pricing');
        } else setLoading(false);
      }
    };

    if (window.location.href.includes('pricing')) {
      setLoading(false);
      return;
    }

    if (user) {
      if (
        user.hasFreeAccess ||
        user.stripeSubscriptionStatus === 'active' ||
        user.stripeSubscriptionStatus === 'trialing'
      ) {
        setLoading(false);
        return;
      } else if (user.stripeSubscriptionStatus?.startsWith('Ending')) {
        checkEndingStatus();

        return;
      } else {
        window.location.assign('/pricing');
      }
    } else {
      api(`${process.env.REACT_APP_API_URL}/app/settings`).then((_user) => {
        setUser(_user ?? null);
      });
    }
  }, [user, pathname]);

  const handleToggle = (name) => {
    setExpandedItems((prev) =>
      prev.includes(name)
        ? prev.filter((item) => item !== name)
        : [...prev, name],
    );
  };

  const renderNavItems = (items) =>
    items.map((item) => (
      <div key={item.name}>
        <div
          className={classNames(
            pathname.endsWith(item.href)
              ? 'bg-yellow-300 hover:bg-yellow-500'
              : 'text-gray-600 hover:bg-gray-200',
            'group flex items-center text-sm rounded px-4 py-2 box-border transition-all',
          )}
          aria-current={item.current ? 'page' : undefined}
        >
          <Link
            to={item.href}
            className={`flex items-center w-full cursor-pointer ${sidebarOpen ? '' : 'items-center justify-center'}`}
            onClick={() => item.subItems && handleToggle(item.name)}
          >
            <item.icon
              className={classNames(
                'flex-shrink-0 items-center justify-center flex',
                sidebarOpen ? 'w-6 h-6 mr-4' : 'w-6 h-6',
                pathname.endsWith(item.href) ? '' : 'text-gray-500',
              )}
              aria-hidden="true"
              strokeWidth={1.5}
            />
            {sidebarOpen && item.name}
            {/* {item.subItems && sidebarOpen && (
              <span className="ml-auto">
                {expandedItems.includes(item.name) ? (
                  <ChevronDownIcon className="w-4 h-4 text-gray-600 " />
                ) : (
                  <ChevronRightIcon className="w-4 h-4 text-gray-600 " />
                )}
              </span>
            )} */}
          </Link>
        </div>
        {item.subItems && sidebarOpen && (
          <div className="space-y-1 rounded py-1">
            {sidebarOpen && item.subItems.map((subItem) => (
              <Link
                key={subItem.name}
                to={subItem.href}
                className={classNames(
                  pathname.endsWith(subItem.href)
                    ? 'bg-yellow-300 hover:bg-yellow-500'
                    : 'text-gray-600 hover:bg-gray-200 transition-all',
                  'group flex items-center px-3 py-2 text-sm cursor-pointer ml-9 rounded',
                )}
                aria-current={subItem.current ? 'page' : undefined}
              >
                {subItem.icon && <subItem.icon className="w-5 h-5 mr-2" strokeWidth={1.5} />}
                {subItem.name}
              </Link>
            ))}
          </div>
        )}
      </div>
    ));

  if (loading) return <Loading />;

  return (
    <>
      <div className={classNames(sidebarOpen ? 'pl-64' : 'pl-16', "min-h-full h-max relative flex-grow flex")}>
        {/* Sidebar */}
        <div
          className={classNames(
            sidebarOpen ? 'w-64' : 'w-16',
            'bg-primary h-screen transition-all duration-100 flex flex-col border-r-2 border-gray-100 fixed top-0 left-0 z-50 px-2',
          )}

        >
          <div className="flex items-center justify-between p-2 mt-4 relative">
            <Link to="/" className='mx-auto relative'>
              {sidebarOpen ? <img
                className={`object-cover ${sidebarOpen ? 'w-44' : 'w-8'
                  } `}
                src={darkMode ? logoWhite : logo}
                alt="Logo"
              />
                : <img
                  className={`object-cover ${sidebarOpen ? 'w-44' : 'w-8'
                    } bg-gray-900 rounded-lg shadow-md box-content p-2 -top-1 relative -left-2`}
                  src={smallLogo}
                  alt="Logo"
                />
              }
            </Link>
            {sidebarOpen && (
              <button onClick={() => setSidebarOpen(false)}>
                <IconLayoutSidebar strokeWidth={1.5} className="w-6 h-6 text-gray-600 absolute transition-all top-4 right-1" />
              </button>
            )}
          </div>
          <nav className="flex-1 overflow-y-auto mt-2">
            <div className="px-1 space-y-1">{renderNavItems(TOP_NAVIGATION)}</div>
          </nav>
          <div
            className={classNames(
              '',
              sidebarOpen
                ? 'flex flex-row items-center space-x-4 justify-center bg-secondary-100 mx-auto mb-4 px-6 py-2 rounded-full'
                : 'flex flex-col items-center space-y-6 py-4 bg-secondary-100 mb-4 rounded-full',
            )}
          >
            {BOTTOM_NAVIGATION.map((item) => (
              <Link key={item.name} to={item.href}>
                <item.icon
                  className={classNames(
                    'text-gray-600 hover:opacity-70 transition-all hover:scale-110',
                    sidebarOpen ? 'w-6 h-6' : 'w-5 h-5',
                    item.name === 'Logout' && 'text-red-500 hover:text-red-700',
                  )}
                  // on click for dark mode
                  onClick={item.name === 'Toggle Dark Mode' ? () => setDarkMode(!darkMode) : null}
                />
              </Link>
            ))}
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 min-h-screen bg-secondary">
          {/* Top menu button when sidebar is collapsed */}
          {!sidebarOpen && (
            <div className="">
              <button onClick={() => setSidebarOpen(true)}>
                <IconLayoutSidebar strokeWidth={1.5} className="w-6 h-6 text-gray-600 absolute left-20 top-8" />
              </button>
            </div>
          )}
          <main className="flex-1 xl:p-10">
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
}
